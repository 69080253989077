import Navbar from "../Home/Navbar";
import Footer from "../../components/Footer";
import careerasset2 from '../../assets/images/Banner/carrierAsset2.webp';
import CareerForm from "../../components/Forms/CareerForm";
import EnquiryProductMainContainer from "../../components/Containers/EnquiryProductMainComponent";
import HelmetSEO from "../../components/HelmetSEO";

const seoTitle = "Advance Metal Powder: Best Company to work with."
const seoDescription = `Advance Metal Powder is the best branded welding consumables in India. Join our team and be part
of a dynamic company that values innovation, quality, and growth. We&#39;re always looking for talented
individuals who are passionate about making an impact in the welding industry.`
export default function Careers() {

  const careerData = {
    title: "Careers",
    description: "Join our team and be part of a dynamic company that values innovation, quality, and growth. We're always looking for talented individuals who are passionate about making an impact in the welding industry.",
    image: careerasset2
  }
  return (<>
    <HelmetSEO  title={seoTitle} description={seoDescription} />
    <Navbar />
    <EnquiryProductMainContainer {...careerData} >
      <CareerForm />
    </EnquiryProductMainContainer>
    <Footer />
  </>)
}