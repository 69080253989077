import EnquiryProductContainer from "./EnquiryProductContainer"
import EnquiryProductSubContainer from "./EnquiryProductSubContainer"

export default function EnquiryProductMainContainer({
  children,
  title,
  description,
  image,
}) {
  return (
    <>
      <div class="pt-[120px]  relative">
        <EnquiryProductContainer>
          <EnquiryProductSubContainer>
            <div className="col-span-1 lg:mt-[140px] z-30 pr-[32px]  lg:pr-[0px] px-[20px] pl-[32px] lg:pl-[80px] xl:pl-[150px]">
              <h4 className="text-#50555B font-Fira-Sans text-[56px] font-[400] leading-[61.6px] tracking-[-4%] mb-[12px]">
                {title}
              </h4>
              <p className="font-Fira-Sans font-[400] text-[20px] leading-[30px] tracking-[-2%] inline">
                {description}{" "}
              </p>
            </div>
            <div className="mt-[84px] lg:mt-[0px] col-span-1 relative lg:pl-[50px] xl:pl-[100px]  max-w-[900px] flex justify-center ">
              <div className="w-full pr-[32px] lg:pr-[80px] xl:pr-[150px]  pl-[32px]  xl:pl-[0px]">
                {children}
              </div>
              <img
                src={image}
                className="w-full absolute top-[100px] right-[0px] lg:right-[90px] object-cover"
              />
              <div className="absolute right-0 top-[0px]"></div>
            </div>
          </EnquiryProductSubContainer>
        </EnquiryProductContainer>
      </div>
    </>
  )
}
