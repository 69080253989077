import { useState } from "react"
import MainContainer from "../../components/Containers/MainContainer"
import certificateFirst from "../../assets/certificates/certificateFirst.pdf"
import certificateSecond from "../../assets/certificates/certificateSecond.pdf"
import certificateThird from "../../assets/certificates/certificateThird.pdf"
import certificateFourth from "../../assets/certificates/certificateFourth.pdf"
import PdfViewer from "../../components/PDFViewer/PDFViewer"
import firstThumbnail from "../../assets/certificates/firstThumbnail.jpg"
import secondThumbnail from "../../assets/certificates/secondThumbnail.jpg"
import thirdThumbnail from "../../assets/certificates/thirdThumbnail.jpg"
import fourthThumbnail from "../../assets/certificates/fourthThumbnail.jpg"
import MainTitleHeading from "../../components/Headings/MainTitleHeading"
import BannerContainer from "../Home/Component/BannerComponents.js/BannerContainer"
import Product1Banner from "../../assets/images/Banner/product1Banner.svg"
import NImage from '../../assets/images/Banner/WeldingBanner4new.webp'
import csm_logo from "../../assets/certificates/csm_tuev.png"
import deutsche_logo from "../../assets/certificates/Deutsche.png"
import nabcb_logo from "../../assets/certificates/nabcb-logo.png"
import tuv_india_logo from "../../assets/certificates/tuv_india_logo.png"
import BannerImg from "../../assets/certificates/certificationBanner.png"
import HelmetSEO from "../../components/HelmetSEO"
const certificateProvidingBodies = [
  csm_logo,
  tuv_india_logo,
  deutsche_logo,
  nabcb_logo,
]
const seoTitle = "Quality Welding Consumables, Iron Powder &amp; 6K Additive Metal Powders in India"
const seoDescription = `Our products meet the highest international quality standards, certified by leading global
institutions. Each product complies with industry benchmarks, guaranteeing safety, performance,
and reliability. These certifications are a testament to our commitment to delivering premium
solutions that meet the demands of professional welders worldwide.`
const Certification = () => {
  const [showPDF, setShowPDF] = useState(null)
  const pdfs = [
    {
      name: "certificateFirst",
      path: certificateFirst,
      thumbnail: firstThumbnail,
    },
    {
      name: "certificateThird",
      path: certificateThird,
      thumbnail: thirdThumbnail,
    },
  ]
  const handlePDFClick = (item) => {
    setShowPDF(item)
  }

  return (
    <>
    <HelmetSEO title={seoTitle} description={seoDescription} />
      <MainContainer>
        <div className="px-[20px] xl:px-[80px] md:px-[40px] flex flex-col gap-[120px]  lg:gap-24  md:gap-28 mb-[190px]">
          <div className="pt-[100px] px-[32px] md:ps-[101px] relative">
            <div className="gap-[72px] lg:max-w-[60%]  relative z-10">
              <MainTitleHeading title="Precision & Reliability in every Project" />
              <p className="text-[56px] leading-[61px] font-[400] font-Fira-Sans mt-[76px]">
                Certification
              </p>
              <p className="text-[20px] font-Fira-Sans font-[400] leading-[30px] mt-[16px]">
                Our products meet the highest international quality standards,
                certified by leading global institutions. Each product complies
                with industry benchmarks, guaranteeing safety, performance, and
                reliability. These certifications are a testament to our
                commitment to delivering premium solutions that meet the demands
                of professional welders worldwide.
              </p>
              <p className="mt-[28px] font-Fira-Sans font-[400] text-[20px] leading-[30px]">
                Welding Excellence backed by two quality certifications!
              </p>

              <div className="flex gap-[20px] mt-[20px]">
                {pdfs?.map((item) => (
                  <div
                    className="thumbnail cursor-pointer"
                    onClick={() => handlePDFClick(item)}
                  >
                    <img
                      src={item.thumbnail}
                      alt="PDF Thumbnail"
                      className="object-contain w-[190px] h-[267px]"
                    />
                  </div>
                ))}
              </div>
              <p className="mt-[60px] font-[400] text-[24px] leading-[25px] font-Fira-Sans">The Certification Body</p>
              <div className="flex gap-[20px] mt-[20px]">
                {certificateProvidingBodies?.map((item) => (
                  <div>
                    <img
                      src={item}
                      alt="PDF Thumbnail"
                      className="object-contain "
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="absolute top-8  bottom-0 right-[0px] 2xl:right-[10%] xl:right-[35px]">
              <img className="object-contain" src={Product1Banner} />
            </div>
          </div>
        </div>
        <img src={BannerImg} alt="BannerImg" className=" w-full mt-[190px]" />
      </MainContainer>

      <PdfViewer pdfFile={showPDF?.path} onClose={() => setShowPDF(null)} />
      
    </>
  )
}

export default Certification
