import React, { useState } from "react"
import Button from "../../../components/Button"
import BannerContainer from "../../Home/Component/BannerComponents.js/BannerContainer"
import WeldingImage from "./../../../assets/images/CSR/csrMelting.webp"
import CellTherapy from "../../../assets/csr/CellTherapy.pdf"
import PdfViewer from "../../../components/PDFViewer/PDFViewer"

const TCellTherapy = () => {
  const [showPDF, setShowPDF] = useState(false)
  const title = `CAR T-cell Therapy`
  const description2 = `As a dedicated donor to Tata Memorial Hospital, Advance Metal Powder is helping to bring life-saving CAR T-cell therapy to young patients in India battling relapsed and refractory B-cell malignancies. Through its support, 22 patients, primarily under 30, have gained access to Actaly-cel, a groundbreaking CAR T-cell therapy developed in collaboration with IIT Bombay and Tata Memorial Centre Mumbai. This initiative addresses a critical need, reducing financial burdens and ensuring these patients receive advanced, effective treatment and supportive care for their journey towards recovery.`
  const description = "Advance Metal Powder’s Commitment to Transforming Cancer Care at Tata Memorial Hospital"

  const list = [
    "This initiative aims to ease the financial burden on young patients at Tata Memorial Hospital who lack adequate healthcare coverage and struggle to afford CAR T-cell therapy and supportive care.",
    `Thanks to the support from Advance Metal Powder, 22 patients, primarily under 30, received vital CAR T-cell therapy for B-cell malignancies, helping those who face economic hardships.`,
    `By March 31, 2024, 20 patients had received apheresed and manufactured treatments, with 12 successfully treated and 5 achieving complete remission, showcasing the project’s impact on patient health.`,
    "This project ensures equitable access to life-saving CAR T-cell therapy, offering young patients timely and affordable treatment to combat cancer.",
    "We at Advance Metal Powder Private Limited are humbled to be associated with Tata Memorial Hospital and to have contributed Rs. 7,45,000/- in support of CAR T-cell therapy treatment costs for patients in need."
  ]
  return (
    <BannerContainer bgImage={WeldingImage} position="left bottom -85px" childrenStyle="!max-w-none pb-[250px]" >
    <div className="grid grid-cols-6 md:gap-x-[88px] pt-[90px] md:pt-[296px] px-[32px] md:px-[80px] xl:px-[150px]" id="carTCellTherapy">
      {/* column 1 */}

      <div className="col-span-6 xl:col-span-3 flex flex-col">
        <h2 className="Banner-title">{title}</h2>

        {/* description*/}
        <p className="approachDescription mt-[36px] !text-[27px]">{description}</p>
        {/* description*/}
        <p className="approachDescription mt-[12px]">{description2}</p>
        <Button title="View Pdf" onClick={()=>setShowPDF(true)} className="mt-[28px]" />
      </div>
      <div className="col-span-6 xl:col-span-3 flex flex-col">
        <div className="grid grid-cols-1 md:grid-cols-2 3xl:grid-cols-3 gap-x-4 ">
          {list.map((activity, index) => (
            <div key={index} className="col-span-1">
              <p className="activityTitle">
                {(index + 1).toString().padStart(2, "0")}.
              </p>
              <p className="activityDescription">{activity}</p>
            </div>
          ))}
        </div>
      </div>
      {showPDF && (
        <PdfViewer
          pdfFile={CellTherapy}
          isMultiFiles={false}
          onClose={() => setShowPDF(false)}
        />
      )}
    </div>
    </BannerContainer>
  )
}

export default TCellTherapy
