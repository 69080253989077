import { toast } from "react-toastify"

const emailjs = require("@emailjs/browser")
let gmail_service_id = "service_i65uk3k"
const publicKey = "2cu3JnLkH-m_0GWI9"
const enquiryTemplateId = "template_mzckxa4"

export const sendEmail = async ({ templateId, params, attachments }) => {
  const serviceId = gmail_service_id

  const selectedTemplateID = templateId || enquiryTemplateId
  const templateParams = {
    to_name: "Parag",
    from_name: params.name,
    ...params,
  }

  // await initialiseEmailJS(
  emailjs.init({ publicKey: publicKey })
  let emailAttachments = []
  if (attachments && !!attachments.length) {
    emailAttachments = attachments.map((attachment) => {
      return { name: attachment.name, data: attachment.data }
    })
  }
  console.log("emailAttachments", emailAttachments)

  emailjs
    .send(
      serviceId,
      selectedTemplateID,
      templateParams,
      emailAttachments,
      publicKey
    )
    .then((response) => {
      console.log("Email sent successfully!", response)
      toast.success("Request Submitted successfully!");
       return response
    })
    .catch((error) => {
      console.error("Error sending email:", error)
      toast.error("Something went wrong, please try again later");
        return error
    })
}