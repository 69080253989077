import React, { useState } from "react"

// PDF paths
import AdvanceForgePDF from "../../assets/brochure/ADVANCE-FORGE.pdf"
import AdvanceProPDF from "../../assets/brochure/ADVANCE-PRO.pdf"
import AdvanceCRAFTPDF from "../../assets/brochure/ADVANCE-CRAFT.pdf"
import HoganasPDF from "../../assets/brochure/Hoganas-Iron-Powder.pdf"
import FullBrochure from "../../assets/brochure/FullBrochure.pdf"
// Thumbnails
import AdvanceCraftThumbnail from "../../assets/brochure/AdvanceCraft.jpg"
import AdvanceForgeThumbnail from "../../assets/brochure/AdvanceForge.jpg"
import AdvanceProThumbnail from "../../assets/brochure/AdvancePro.jpg"
import FullBrochureThumbnail from "../../assets/brochure/FullBrochure.jpg"
import HoganasThumbnail from "../../assets/brochure/Hoganas.jpg"
// other imports
import MainContainer from "../../components/Containers/MainContainer"
import DownloadPdfForm from "./component/DownloadPdfForm"
import MainTitleHeading from "../../components/Headings/MainTitleHeading"
import DownloadIcon from "../../assets/brochure/DownloadIcon"
import Product1Banner from "../../assets/Downloads/bannerImg.svg"
import BannerImg from "../../assets/brochure/downloadBanner.png"
import ProductTechnicalSection from "./component/ProductTechnicalSection"
import HelmetSEO from "../../components/HelmetSEO"
import { downloadPDF } from "../../Utility/download"

const seoTitle = "Leading welding consumable brand in India: Advance Pro, Advance Craft, Advance Forge in TIG, MIG, SAW, CORE form."
const seoDescription = `Advance Metal Powder is the best branded welding consumables in India: Advance Pro, Advance
Craft, Advance Forge in TIG, MIG, SAW, CORE form. Hoganas Iron Powder &amp; Additive Metal powder
for high performance applications.`
const Download = () => {
  const [selectedPDF, setSelectedPDF] = useState(null)
  const pdfs = [
    {
      name: "Nickel Alloy Wires",
      path: AdvanceProPDF,
      thumbnail: AdvanceProThumbnail,
      size: "1 mb",
    },
    {
      name: "Stainless Steel Wires ",
      path: AdvanceCRAFTPDF,
      thumbnail: AdvanceCraftThumbnail,
      size: "913 kb"
    },
    {
      name: "Other Alloy Wires",
      path: AdvanceForgePDF,
      thumbnail: AdvanceForgeThumbnail,
      size: "746 kb"
    },
    {
      name: "Hoganas Iron Powder",
      path: HoganasPDF,
      thumbnail: HoganasThumbnail,
      size: "746 kb"
    },
    {
      name: "Full Brochure",
      path: FullBrochure,
      thumbnail: FullBrochureThumbnail,
      size: "746 kb"
    },
    
   
  ]
  const handlePDFClick = (item) => {
    setSelectedPDF(item)
  }
  return (
    <>
    <HelmetSEO title={seoTitle} description={seoDescription} />
      <MainContainer>
        <div className="px-[20px] xl:px-[80px] md:px-[40px] flex flex-col gap-[120px]  lg:gap-24  md:gap-28">
          <div className="pt-[100px] px-[32px] md:ps-[101px] relative">
            <div className="gap-[72px] lg:max-w-[60%]  relative z-10">
              <MainTitleHeading title="The first step to making informed decisions" />
              <p className="text-[56px] leading-[61px] font-[400] font-Fira-Sans mt-[76px]">
                Downloads
              </p>
              <p className="text-[20px] font-Fira-Sans font-[400] leading-[30px] mt-[16px]">
                Explore our comprehensive range of welding consumables with easy
                access to product catalogues and detailed technical data sheets.
                Download PDFs for in-depth product specifications, performance
                insights, and application guidelines to help you choose the
                right solution for your welding needs. Stay informed and ensure
                you have the latest product information at your fingertips.
              </p>
              <div className="flex flex-col mt-[43px]">
                <p className="font-Fira-Sans font-[300] text-[24px] leading-[25.2px] tracking-[2%] text-[#003088]">
                  Product Brochures
                </p>
                <div className="grid grid-cols-1 md:grid-cols-3  mt-[34px] gap-x-[48px] gap-y-[68px]">
                  {pdfs?.map((item) => (
                    <div
                      className="col-span-1 cursor-pointer"
                      onClick={() => handlePDFClick(item)}
                    >
                      <img
                        src={item.thumbnail}
                        alt="PDF Thumbnail"
                        className="obejct-contain  border-[1px] border-solid border-[#54565A]"
                      />
                      <div className="flex justify-between mt-[8px]">
                        <div>
                          <div className="font-Fira-Sans font-[400] text-[20px] leading-[30px]">
                            {item.name}
                          </div>
                          <div className="font-Fira-Sans font-[400] text-[14px] leading-[21px] text-[#02111B]">{`(Size approx. ${item?.size})`}</div>
                        </div>
                        <DownloadIcon />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="absolute top-8  bottom-0 right-[0px] 2xl:right-[10%] xl:right-[35px] justify-end flex">
              <img className="object-contain max-w-[350px] " src={Product1Banner} />
            </div>
            
          </div>
          <ProductTechnicalSection />
        </div>
        <img src={BannerImg} alt="BannerImg" className=" w-full mt-[190px] " />
      </MainContainer>
      <DownloadPdfForm
        isOpen={!!selectedPDF}
        onClose={() => setSelectedPDF(null)}
        onSubmitSuccess={() => {
          downloadPDF(selectedPDF.path, `${selectedPDF.name}.pdf`)
        }}
        pdfname={selectedPDF?.name}
      />
    </>
  )
}

export default Download
